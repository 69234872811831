import Header from './components/Header'
import Tasks from './components/Tasks'
import AddTask from './components/AddTask'
import Footer from './components/Footer'
import About from './components/About'

import { BrowserRouter as Router, Route} from 'react-router-dom'
import { useState, useEffect } from 'react'

const App = () => {
  const [showAddTask, setShowAddTask] = useState(false)

  const [tasks, setTasks] = useState([])

  useEffect(() => {
    const getTasks = async () => {
      const tasksFromServer = await fetchTasks()
      setTasks(tasksFromServer)
    }

    getTasks()
  }, [])

  // fetch tasks from backend
  const fetchTasks = async () => {
    const res = await fetch('https://react-task-tracker-api-cr-staging.joelduda.com/tasks')
    const data = await res.json()
    
    return data
  }

    // fetch task from backend
    const fetchTask = async (id) => {
      const res = await fetch(`https://react-task-tracker-api-cr-staging.joelduda.com/tasks/${id}`)
      const data = await res.json()
      
      return data
    }
  

  // toggle reminder
  const toggleReminder = async (id) => {
    const taskToToggle = await fetchTask(id)
    const updTask = {...taskToToggle, reminder: !taskToToggle.reminder}

    const res = await fetch(`https://react-task-tracker-api-cr-staging.joelduda.com/tasks/${id}`,
    {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(updTask)
    })

    const data = await res.json()

    setTasks(
        tasks.map((task) => task.id === id ? {...task, reminder:data.reminder} : task)
      )
  }

  // add task
  const addTask = async (task) => {
    const res = await fetch('https://react-task-tracker-api-cr-staging.joelduda.com/tasks', 
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(task)
      }
    )

    const data = await res.json()
    setTasks([...tasks, data])

    // const id = Math.floor(Math.random() * 10000) + 1

    // const newTask = { id, ...task}
    // setTasks([...tasks, newTask])
  }

  // delete task
  const deleteTask = async (id) => {
    await fetch(`https://react-task-tracker-api-cr-staging.joelduda.com/tasks/${id}`, { method: 'DELETE'})

    console.log('delete', id)
    setTasks(tasks.filter((task) => task.id !== id))
  }

  return (
    <Router>
      <div className="container">
        <Header onAdd={() => setShowAddTask(!showAddTask)} showAdd={showAddTask}/>

        <Route path='/' exact render={(props) => (
          <>
            {showAddTask && <AddTask onAdd={addTask}/>}
            {tasks.length > 0 ? (<Tasks tasks={tasks} onToggle={toggleReminder} onDelete={deleteTask}/>) : ('No tasks to Show')}
          </>
        )} />
        <Route path='/about' component={About} />
        <Footer />
      </div>
    </Router>

  );
}

export default App;
